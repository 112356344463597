













































@import "./assets/style/index";
 

.hideSidebar {
  width: 64px !important;
}

.container {
  display: flex;
  flex: 1;
  height: 100vh;
}

.sidebar-container {
  transition: width 0.28s ease-out;
  width: 180px;   
  overflow-y: auto;
  overflow-x: hidden;
  margin-block-start: 0!important;
  margin-block-end: 0!important;
  padding-inline-start: 0!important;
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column; 
  overflow: hidden;
}

.mainContainerExpand {
  left: 64px; 
}

.el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}
 .el-dialog__header {
  border-bottom: solid 1px #ddd;
  text-align: center;
}
.el-dialog__body {
  background: #f9f9f9;
  padding: 30px 40px !important;
}


@primary-color: #1DA57A;