








































































































a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

@primary-color: #1DA57A;