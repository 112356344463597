



































































.login-container {
  width: 100%;
  height: 100%;
  background: url("../assets/images/bg_login.jpg") no-repeat;
  background-size: cover;
  position: fixed;


  .logo {
    margin: 100px auto 30px;
    width: 200px;

    img {
      width: 200px;
    }
  }

  h1 {
    color: white;
    font-size: 20px;
    text-align: center;
  }
}

.form-style {
  margin: 80px auto 0;
  width: 300px;
}

.btn-login {
  width: 100%;
}


@primary-color: #1DA57A;