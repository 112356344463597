

















































.navbar {
  height: 51px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 25px 0 15px; 
  border-bottom: 1px solid #E4E7ED;
}

.hamburger {
  display: inline-block;
  float: left;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  transition: .38s;
  transform-origin: 50% 50%;
}

.hamburger.is-active {
  transform: rotate(90deg);
}

.avatar-container {
  position: fixed;
  right: 15px;
  cursor: pointer;
}

.user-avatar {
  display: inline-block;
  background: url("../../assets/images/ic_avatar.png") no-repeat;
  width: 40px;
  height: 40px;
  background-size: cover;
  margin-right: 5px;
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}
.loginInfo{
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}


@primary-color: #1DA57A;