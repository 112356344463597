

















































































































































































































































































































.edit-container {
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.msg-view {
  flex: 1;
}

.msg-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #E4E7ED;
  width: 100%;
  font-weight: bold;
  font-size: 14px;

  span {
    margin-left: 10px;
  }
}

.formData {
  padding: 30px 0 10px 0;
}

.el-input {
  /deep/ .el-input__inner {
    width: 300px;
  }
}

.el-textarea {
  /deep/ .el-textarea__inner {
    width: 500px;
  }
}

.quill-editor {
  /deep/ .ql-container {
    min-height: 300px !important;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.tips{
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

@primary-color: #1DA57A;