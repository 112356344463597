
.list-container {
  margin: 0px 20px 20px;
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;

  .list-header {
    color: #333;
    vertical-align: middle;
    height: auto !important;
    padding: 0px;
  }

  .list-main {
    color: #333;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f2f2f2;
      border: 1px solid #EBEEF5;
      border-bottom: 0px;
      padding: 10px 0px 10px 10px;
      height: 37px;
    }

    .table-container {
      flex: 1;
      display: flex;
      overflow: hidden;
      margin-bottom: 10px;
    }

  }
}

.el-form-item {
  margin-bottom: 0px;
}

.el-table {
  overflow-y: auto;
}

.table-tool {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  text-align: center;
  color: #333;
  border: 1px solid rgba(25, 31, 37, .14);
  cursor: pointer;
  -webkit-transition: .5s all;
  transition: .5s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.el-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /deep/ span {
    height: auto !important;
    vertical-align: middle !important;
    line-height: 36px !important;
  }

  /deep/ .el-pagination__editor {
    height: auto !important;
  }

  /deep/ button {
    height: 100% !important;
    vertical-align: middle !important;
  }

  /deep/ .el-pager, .el-pager li {
    vertical-align: middle !important;
  }
}

.el-icon-refresh {
  color: #7b7b7b;

  &:hover {
    color: #333;
  }
}
.cell{
  img{
    max-height: 50px;
  }
}
