* .el-tabs__content {
  flex: 1;
  display: flex;

  .el-tab-pane {
    flex: 1;
    display: none;
  }

  .el-message-box__header {
    padding-top: 0px;
  }
}

.el-message-box__message p {
  font-weight: bold;
  text-align: left;
}

.el-message-box--center {
  .el-message-box__header {
    padding-top: 0px !important;
  }

  .el-message-box__title {
    line-height: 30px;
  }
}

a, a:focus, a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.el-input__inner {
  line-height: 36px !important;
  height: 36px !important;
}

.el-tabs__header {
  margin: 0 0 10px !important;
}

.search-input {
  width: 200px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.el-select {
  margin-right: 10px !important;
}

.el-button {
  padding: 10px 24px !important;
}

.el-menu {
  border: none !important;
}

.el-submenu .el-menu-item {
  min-width: 180px !important;
}

 * {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    background: #dfe0e1;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background: #f5f5f5;
  }
}
