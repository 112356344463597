






























































.home-container {
  margin: 20px;
}

.user-avatar {
  display: inline-block;
  background: url("../../assets/images/ic_avatar.png") no-repeat;
  width: 80px;
  height: 80px;
  background-size: cover;
  margin-right: 5px;
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}

.company-info {
  margin-top: 20px;

  &-content {
    img {
      width: 200px;
    }

    div {
      margin-top: 20px;

      h3 {
        line-height: 25px;
        font-size: 14px;
      }
    }
  }
}

.title {
  font-size: 16px;
  color: #3c3c3c;
  font-weight: bold;
  margin-right: 10px;
}

.company-info .title:hover {
  cursor: pointer;
  color: #409EFF;
}

.platform-info-content {
  display: flex;
  align-items: center;

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    span:first-child {
      height: 18px;
      font-size: 18px;
      color: #3c3c3c;
    }

    span:last-child {
      height: 14px;
      font-size: 14px;
      color: #676a6c;
      margin-top: 10px;
    }
  }
}

@primary-color: #1DA57A;