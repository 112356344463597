.list-container {
  margin: 0px 20px 20px;
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}
.list-container .list-header {
  color: #333;
  vertical-align: middle;
  height: auto !important;
  padding: 0px;
}
.list-container .list-main {
  color: #333;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.list-container .list-main .tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #EBEEF5;
  border-bottom: 0px;
  padding: 10px 0px 10px 10px;
  height: 37px;
}
.list-container .list-main .table-container {
  flex: 1;
  display: flex;
  overflow: hidden;
  margin-bottom: 10px;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-table {
  overflow-y: auto;
}
.table-tool {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  text-align: center;
  color: #333;
  border: 1px solid rgba(25, 31, 37, 0.14);
  cursor: pointer;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.el-pagination /deep/ span {
  height: auto !important;
  vertical-align: middle !important;
  line-height: 36px !important;
}
.el-pagination /deep/ .el-pagination__editor {
  height: auto !important;
}
.el-pagination /deep/ button {
  height: 100% !important;
  vertical-align: middle !important;
}
.el-pagination /deep/ .el-pager,
.el-pagination .el-pager li {
  vertical-align: middle !important;
}
.el-icon-refresh {
  color: #7b7b7b;
}
.el-icon-refresh:hover {
  color: #333;
}
.cell img {
  max-height: 50px;
}
