





































































.imageCarousel {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
}

.image-box {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.image-left {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-left-arrow {
  width: 15px;
  height: 26px;
  background: url("../../assets/images/ic_goback.png") no-repeat;
  transform: rotate(180deg);
  background-size: cover;
}

.image-right-arrow {
  width: 15px;
  height: 26px;
  background: url("../../assets/images/ic_goback.png") no-repeat;
  background-size: cover;
}

.image-right {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-content {
  flex: 1;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 380px;
}


@primary-color: #1DA57A;